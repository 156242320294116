/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "~@coreui/coreui/scss/coreui";
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Import Chart.js custom tooltips styles
@import "~@coreui/chartjs/scss/coreui-chartjs";

// perfect scrollbar theming for use with [perfectScrollbar] directive
//@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import "layout";

@import "~bootstrap/scss/bootstrap";

//Date time picker 
@import "~ng-pick-datetime/assets/style/picker.min.css";

// Some temp fixes
@import "fixes";


// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

.modal-dialog {
    width: 360px !important;
    margin-top: 120px;
    margin: 30px auto;
    .modal-content {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }
    .confirm {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      min-height: 150px;
      .content {
        padding: 20px;
        border-radius: 10px;
        background-color: #fff !important;
        font-weight: 700;
      }
      .buttons {
        padding: 10px 15px;
        float: right;
        button {
          margin-right: 10px;
        }
      }
    }
  }

 label{
    font-weight: 700;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
    color: #525f7f;
}
  
  /* Importing Bootstrap SCSS file. */
  @import '~bootstrap/scss/bootstrap';
  
  body {
      font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
      font-weight: 400;
      overflow-x: hidden;
      overflow-y: auto;
    }